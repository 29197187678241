import { useState, MouseEvent } from "react";
import { useRouter } from "next/router";

import { useCreateB2CHCostCounsel } from "../framework/cost";
import { MODALV2 } from "../constants";
import { ROUTE, ROUTE_ORDER } from "../constants/route";
import { setSessionStorage } from "@vhows/util";
import client from "../framework/client";
import useAuth from "./useAuth";

const useCounselProgress = () => {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const { mutateAsync } = useCreateB2CHCostCounsel();

  const { counselProgress, isLoggedIn } = useAuth();

  const counselStatus: "BLOCK" | "OLD" | "NEW" = counselProgress.consultingUuid
    ? "BLOCK"
    : counselProgress.preparationUuid
    ? "OLD"
    : "NEW";

  const counselModalData =
    counselStatus === "BLOCK" ? MODALV2.IS_PROGRESS_COUNSEL : counselStatus === "OLD" ? MODALV2.CONTINUE_COUNSEL : null;

  const createCounsel = async () => {
    try {
      await mutateAsync(
        {
          stepCode: ROUTE.LANDING,
          serviceVersion: "v6.0.0",
        },
        {
          onSuccess: data => setSessionStorage("uuid", data.uuid),
        },
      );
      router.push(ROUTE.FACIL_TYPE);
    } catch (e) {
      throw e;
    }
  };

  const handleStartCounsel = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (counselStatus !== "NEW") {
      setIsVisible(true);
    } else {
      await createCounsel();
    }
  };

  const handlePrimaryClick = async (isCounsel: "BLOCK" | "OLD" | "NEW") => {
    if (isCounsel === "OLD") {
      setSessionStorage("uuid", counselProgress.preparationUuid);

      const counsel = await client.costs._getB2CHCostCounsel(counselProgress.preparationUuid as string);
      const currentRouteIndex = ROUTE_ORDER.indexOf(counsel.stepCode);
      const resultIndex = ROUTE_ORDER.indexOf(ROUTE.RESULT);

      if (currentRouteIndex < resultIndex) {
        router.push(ROUTE.FACIL_TYPE);
      } else {
        router.push(ROUTE.RESULT);
      }
    } else if (isCounsel === "BLOCK") {
      router.push(`/detail/${counselProgress.consultingUuid}`);
    }
  };

  const handleSecondaryClick = async (isCounsel: "BLOCK" | "OLD" | "NEW") => {
    if (isCounsel === "OLD") {
      await createCounsel();
    } else {
      setIsVisible(false);
    }
  };

  const primaryText = counselProgress.lastCounselFlag ? "견적 시작하기" : "간편 견적 시작하기";

  return {
    handlePrimaryClick,
    handleSecondaryClick,
    handleStartCounsel,
    counselModalData,
    isVisible,
    counselStatus,
    primaryText,
    isLoggedIn,
  };
};

export default useCounselProgress;
