import { TextBody } from "@greenlabs/formula-components";
import dynamic from "next/dynamic";

import { Modal } from "../../components/cost/modal";
import useCounselProgress from "../../hooks/useCounselProgress";

const AppLanding = dynamic(() => import("feature/design-counsel/entrypoint/entrypoint-v2"));
const WebLanding = dynamic(() => import("feature/landing/landing-v2"));

interface EnvironmentLandingProps {
  isApp: boolean;
}

const EnvironmentLanding = ({ isApp }: EnvironmentLandingProps) => {
  const counselProgress = useCounselProgress();

  return (
    <>
      {isApp ? <AppLanding {...counselProgress} /> : <WebLanding {...counselProgress} />}
      {counselProgress.isVisible && counselProgress.counselModalData && (
        <Modal>
          <div className="w-full p-5">
            <div className="mb-6">
              {counselProgress.counselModalData.title.map((v, i) => (
                <h2 className="mt-1 text-[22px] font-bold" key={`title_${i}`}>
                  {v}
                </h2>
              ))}
            </div>
            <div className="mb-6">
              <TextBody weight="regular" size="md" color="neutral-secondary-contents">
                {counselProgress.counselModalData.content}
              </TextBody>
            </div>
            <button
              data-cy="counsel-modal-secondary-btn"
              className="mr-2 w-[calc(50%_-_0.25rem)] rounded-xl bg-[#ECECEC] py-3 text-[17px] font-medium leading-7"
              onClick={() => counselProgress.handleSecondaryClick(counselProgress.counselStatus)}
            >
              {counselProgress.counselModalData.secondaryLabel}
            </button>
            <button
              data-cy="counsel-modal-primary-btn"
              className="w-[calc(50%_-_0.25rem)] rounded-xl border border-solid border-transparent bg-[#12B564] py-3 text-[17px] font-medium leading-7 text-white"
              onClick={() => counselProgress.handlePrimaryClick(counselProgress.counselStatus)}
            >
              {counselProgress.counselModalData.primaryLabel}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default EnvironmentLanding;
