export const AUTH_ACCESS_TOKEN_KEY = "accessToken";
export const AUTH_REFRESH_TOKEN_KEY = "refreshToken";
export const RTL_LANGUAGES: ReadonlyArray<string> = ["ar", "he"];

export const MODAL = {
  IS_PROGRESS_COUNSEL: {
    title: ["이미 신청된 상담건 있습니다.", "진행 상황을 확인하시겠어요?"],
    content: "진행 중인 상담이 완료된 후 다른 상담 신청이 가능해요.",
    primaryLabel: "상담 내역으로",
    secondaryLabel: "닫기",
  },
  CONTINUE_COUNSEL: {
    title: ["작성 중인 신청서가 있습니다.", "이어서 작성하시겠어요?"],
    content: "중단했던 부분 부터 이어 작성할 수 있어요.",
    primaryLabel: "이어 작성하기",
    secondaryLabel: "새로 작성하기",
  },
};

export const MODALV2 = {
  IS_PROGRESS_COUNSEL: {
    title: ["이미 신청된 견적건 있습니다.", "진행 상황을 확인하시겠어요?"],
    content: "진행 중인 상담이 완료된 후 다른 상담 신청이 가능해요.",
    primaryLabel: "상담 내역으로",
    secondaryLabel: "닫기",
  },
  CONTINUE_COUNSEL: {
    title: ["작성 중인 견적 요청이 있습니다.", "이어서 작성하시겠어요?"],
    content: "중단했던 부분 부터 이어 작성할 수 있어요.",
    primaryLabel: "이어 작성하기",
    secondaryLabel: "새로 작성하기",
  },
  COUNSEL_CANCEL: {
    title: ["견적 요청을 취소하시겠어요?"],
    content: "[요청 취소하기] 버튼을 누르면 요청정보가 삭제돼요. 취소 후에는 정보를 다시 되돌릴 수 없어요.",
    primaryLabel: "요청 취소하기",
    secondaryLabel: "유지하기",
  },
  CHANGE_FACIL_TYPE: {
    title: ["시설 유형을 새로 선택하시겠어요?"],
    content: "시설 유형을 변경하면 시설 규격과 관심 시설을 새로 선택해야 해요",
    primaryLabel: "새로 선택하기",
    secondaryLabel: "취소",
  },
};
