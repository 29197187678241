import { getFarmmorningAccessToken, getFarmmorningUserInfo } from "@vhows/rest";
import { SetStateAction, useEffect } from "react";
import { getLocalStorage, setLocalStorage } from "../storage";

const FARMMORNING_ACCESS_TOKEN = "fm-at";
const FARMMORNING_REFRESH_TOKEN = "fm-rt";

type UserInfo = {
  communityUser: {
    userAddress: {
      address1: string;
      address2: string;
      address3: string;
    } | null;
  };
  uuid: string;
  name: string;
  phoneNum: string;
} | null;
export default function useGetUserInfo(
  setFmUserInfo: (update: SetStateAction<UserInfo>) => void,
  login: (data: UserInfo) => Promise<void>,
) {
  useEffect(() => {
    entryPointCheck();
  }, []);

  const entryPointCheck = async () => {
    const accessToken = getLocalStorage(FARMMORNING_ACCESS_TOKEN);
    const refreshToken = getLocalStorage(FARMMORNING_REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      console.log("토큰 존재");
      try {
        const { data, errors } = await getFarmmorningUserInfo(accessToken);

        if (data) {
          setFmUserInfo(data.me);

          await login(data.me);
        } else if (errors && errors?.find((v: any) => v.message === "Unauthorized")) {
          // 토큰이 비정상적인 경우
          const result = await getFarmmorningAccessToken(refreshToken);
          if (result.access_token) {
            setLocalStorage(FARMMORNING_ACCESS_TOKEN, result.access_token);
            const { data } = await getFarmmorningUserInfo(result.access_token);
            if (data) {
              setFmUserInfo(data.me);
              await login(data.me);
            }
          }
        }
      } catch (e: any) {
        if (e?.response?.status === 401) {
          const result = await getFarmmorningAccessToken(refreshToken);
          if (result.access_token) {
            setLocalStorage(FARMMORNING_ACCESS_TOKEN, result.access_token);
            const { data } = await getFarmmorningUserInfo(result.access_token);
            if (data) {
              setFmUserInfo(data.me);
              await login(data.me);
            } else {
              throw e;
            }
          }
        } else {
          throw e;
        }
      }
    }
  };
}
