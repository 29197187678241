export const setLocalStorage = (key: string, value: any) => {
  if (typeof window !== "undefined" && localStorage) {
    const val = typeof value === "string" || typeof value === "number" ? value.toString() : JSON.stringify(value);
    localStorage.setItem(key, val);
  } else {
    console.log("localStorage is undefined");
  }
};

export const getLocalStorage = (key: string, defaultValue?: any) => {
  if (typeof window !== "undefined" && localStorage && !!localStorage.getItem(key)) {
    let jsonData = "";
    try {
      jsonData = JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      // console.log(localStorage.getItem(key), "is not JSON type");
      jsonData = JSON.parse(JSON.stringify(localStorage.getItem(key) as string));
    }
    return jsonData;
  } else {
    // console.log(key, "localStorage is undefined or value is null");
    return !!defaultValue ? defaultValue : "";
  }
};

export const setSessionStorage = (key: string, value: any) => {
  if (typeof window !== "undefined" && sessionStorage) {
    const val = typeof value === "string" || typeof value === "number" ? value.toString() : JSON.stringify(value);
    sessionStorage.setItem(key, val);
  } else {
    console.log("sessionStorage is undefined");
  }
};

export const getSessionStorage = (key: string, defaultValue?: any) => {
  if (typeof window !== "undefined" && sessionStorage && !!sessionStorage.getItem(key)) {
    let jsonData = "";
    try {
      jsonData = JSON.parse(sessionStorage.getItem(key) as string);
    } catch (e) {
      // console.log(sessionStorage.getItem(key), "is not JSON type");
      jsonData = JSON.parse(JSON.stringify(sessionStorage.getItem(key) as string));
    }
    return jsonData;
  } else {
    // console.log(key, "sessionStorage is undefined or value is null");
    return !!defaultValue ? defaultValue : "";
  }
};
