import { useAtom, useSetAtom } from "jotai";
import { nanoid } from "nanoid";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";

import { getFarmmorningAccessToken } from "@vhows/rest";
import { getLocalStorage, setLocalStorage } from "@vhows/util";
import useGetUserInfo from "@vhows/util/farmmorning/useGetUserInfo";
import client from "../framework/client";
import jwtService from "../framework/client/http-client";
import { counselProgressAtom, farmmorningUserInfoAtom, toastAtom } from "../store/global";

const useAuth = () => {
  const router = useRouter();
  const setToast = useSetAtom(toastAtom);
  const isLoggedIn = useRef(false);
  const [counselProgress, setCounselProgress] = useAtom(counselProgressAtom);
  const isRefreshRequest = useRef(false);
  const setFmUserInfo = useSetAtom(farmmorningUserInfoAtom);

  const getCounselList = useCallback(async () => {
    try {
      const counselProgress = await client.costs._getB2CHCostProgress();

      setCounselProgress(counselProgress);
    } catch (e) {
      throw e;
    }
  }, [setCounselProgress]);

  const login = async () => {
    const fmAt = getLocalStorage("fm-at");
    try {
      const { data } = await jwtService.login({
        accessToken: fmAt,
      });

      jwtService.setToken(data.accessToken);
      jwtService.setRefreshToken(data.refreshToken);
      isLoggedIn.current = true;

      await getCounselList();
    } catch (e: any) {
      isLoggedIn.current = false;
      if (e?.response?.status === 401 && !isRefreshRequest.current) {
        isRefreshRequest.current = true;
        const fmRt = getLocalStorage("fm-rt");
        const result = await getFarmmorningAccessToken(fmRt);

        if (result.access_token) {
          setLocalStorage("fm-at", result.access_token);
          await login();
        }
      } else if (e?.response?.status === 401 && isRefreshRequest.current) {
        router.push("/error");
        throw e;
      } else {
        setToast(prev => ({ ...prev, visible: true }));
        throw e;
      }
    }
  };

  const guestLogin = useCallback(async () => {
    let tid = jwtService.getTempId();
    if (!tid) tid = nanoid();
    try {
      const { data } = await jwtService.guestLogin({
        guestKey: tid,
      });

      jwtService.setToken(data.accessToken);
      jwtService.setTempId(tid);
      jwtService.setRefreshToken(data.refreshToken);
      isLoggedIn.current = true;

      await getCounselList();
    } catch (e) {
      setToast(prev => ({ ...prev, visible: true }));
      isLoggedIn.current = false;
      throw e;
    }
  }, [getCounselList, setToast]);

  useEffect(() => {
    const fmAt = getLocalStorage("fm-at");
    if (!fmAt) {
      guestLogin();
    }
  }, [guestLogin]);

  useGetUserInfo(setFmUserInfo, login);

  return {
    counselProgress,
    isLoggedIn: isLoggedIn.current,
  };
};

export default useAuth;
